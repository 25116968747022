@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.tile {
  justify-content: center;
  align-items: center;
}

.tile::before {
  content: "";
  display: inline-block;
  padding-bottom: 100%;
}
